import { extendWithAuthorization, http } from '@/http'
import { firebaseApp } from '@/main.js'
import { getFirestore, collection, query, where, orderBy, getDocs, setDoc, doc, deleteDoc } from 'firebase/firestore'
import { reactive, readonly, toRefs } from 'vue'

const db = getFirestore(firebaseApp)

const state = reactive({
  currentPolls: [],
  nextPolls: [],
  previousPolls: []
})

export default function () {
  const resetStates = () => {
    state.currentPolls = []
    state.nextPolls = []
    state.previousPolls = []
  }

  const getVotes = async proposal => {
    try {
      const votesCollectionRef = collection(db, `SMARTLIKE/DATA/PROPOSALS/${proposal.id}/votes`)
      const querySnapshot = await getDocs(votesCollectionRef)

      let upVote = 0,
        downVote = 0

      querySnapshot.forEach(doc => {
        upVote += doc.get('upVote') ?? 0
        downVote += doc.get('downVote') ?? 0
      })

      return { ...proposal, upVote, downVote }
    } catch (error) {
      console.error('Error getting votes:', error)
    }
  }

  const fetchCurrentPolls = async () => {
    try {
      if (!state.currentPolls.length) {
        const proposalsCollectionRef = collection(db, 'SMARTLIKE/DATA/PROPOSALS')
        const pollsQuery = query(proposalsCollectionRef, where('end', '>=', new Date()), orderBy('end', 'desc'))
        const querySnapshot = await getDocs(pollsQuery)

        let polls = querySnapshot.docs
          .map(doc => {
            const data = doc.data()
            const start = data.start.toMillis()
            const end = data.end.toMillis()
            if (new Date() > new Date(start)) return { ...data, id: doc.id, start, end }
          })
          .filter(p => p)

        const promises = polls.map(p => getVotes(p))
        state.currentPolls = await Promise.all(promises)

        console.log('Current polls fetched successfully:', state.currentPolls)
      }
    } catch (error) {
      console.error('Error fetching current polls:', error)
    }
  }

  const fetchPreviousPolls = async () => {
    try {
      if (!state.previousPolls.length) {
        const proposalsCollectionRef = collection(db, 'SMARTLIKE/DATA/PROPOSALS')
        const pollsQuery = query(proposalsCollectionRef, where('start', '<=', new Date()), orderBy('start', 'desc'))
        const querySnapshot = await getDocs(pollsQuery)

        let polls = querySnapshot.docs
          .map(doc => {
            const data = doc.data()
            const start = data.start.toMillis()
            const end = data.end.toMillis()
            if (new Date() > new Date(end)) return { ...data, id: doc.id, start, end }
          })
          .filter(p => p)

        const promises = polls.map(p => getVotes(p))
        state.previousPolls = await Promise.all(promises)

        console.log('Previous polls fetched successfully:', state.previousPolls)
      }
    } catch (error) {
      console.error('Error fetching previous polls:', error)
    }
  }

  const fetchNextPolls = async () => {
    try {
      if (!state.nextPolls.length) {
        const proposalsCollectionRef = collection(db, 'SMARTLIKE/DATA/PROPOSALS')
        const pollsQuery = query(proposalsCollectionRef, where('start', '>', new Date()), orderBy('start', 'asc'))
        const querySnapshot = await getDocs(pollsQuery)

        let polls = querySnapshot.docs.map(doc => {
          const data = doc.data()
          const start = data.start.toMillis()
          const end = data.end.toMillis()
          return { ...data, id: doc.id, start, end }
        })

        const promises = polls.map(p => getVotes(p))
        state.nextPolls = await Promise.all(promises)

        console.log('Next polls fetched successfully:', state.nextPolls)
      }
    } catch (error) {
      console.error('Error fetching next polls:', error)
    }
  }

  const updatePoll = async ({ id, ...poll }) => {
    try {
      const start = new Date(poll.start)
      const end = new Date(poll.end)
      const documentRef = doc(db, `SMARTLIKE/DATA/PROPOSALS/${id}`)
      await setDoc(documentRef, { ...poll, start, end }, { merge: true })
      resetStates()
    } catch (error) {
      console.error('Error update poll:', error)
    }
  }

  const addPoll = async poll => {
    const extendedHttp = await extendWithAuthorization(http)
    await extendedHttp.put('like/proposal', { json: poll })
    resetStates()
  }

  const deletePoll = async ({ id }) => {
    try {
      const documentRef = doc(db, `SMARTLIKE/DATA/PROPOSALS/${id}`)
      await deleteDoc(documentRef)
      resetStates()
    } catch (error) {
      console.error('Error delete poll:', error)
    }
  }

  return {
    ...toRefs(readonly(state)),
    fetchCurrentPolls,
    fetchPreviousPolls,
    fetchNextPolls,
    updatePoll,
    addPoll,
    deletePoll
  }
}
